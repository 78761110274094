import { useAuthStore } from '@/stores/useAuthStore';

export default defineNuxtRouteMiddleware((to) => {
    const auth = useAuthStore();

    const status = auth.isLoggedIn;

    if (!status && to?.name !== 'login') {
        auth.redirectTo = to.fullPath || '/';
    }

    if (auth.redirectTo === '/login') {
        auth.redirectTo = '/';
    }
});
